<template>
  <div>
    <vs-row vs-justify="center">
      <vs-col
        type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="12"
      >
        <b-card>
          <div slot="header">
            <h3>
              {{ $t('Edit User') }}
            </h3>
          </div>
          <div>
            <vs-row
              v-if="EditedUserData"
              type="flex"
              vs-justify="space-around"
              vs-align="center"
            >
              <vs-col
                vs-w="2"
                vs-sm="12"
              >
                <vs-row>
                  <vs-col
                    vs-type="flex"
                    vs-align="center"
                    vs-justify="center"
                    vs-w="12"
                  >
                    <vx-upload
                      v-model="EditedUserData.avatar_id"
                      class="mt-2"
                    />
                  </vs-col>
                </vs-row>
              </vs-col>
              <vs-col
                vs-w="8"
                vs-sm="12"
              >
                <b-form @submit.prevent>
                  <b-row>
                    <b-col md="6">
                      <vx-input
                        v-model="EditedUserData.first_name"
                        class="mt-2"
                        :label="$t('First Name')"
                        :errors="errors.first_name"
                      />
                    </b-col>
                    <b-col md="6">
                      <vx-input
                        v-model="EditedUserData.last_name"
                        class="mt-2"
                        :label="$t('Last Name')"
                        :errors="errors.last_name"
                      />
                    </b-col>
                    <b-col md="6">
                      <vx-input
                        v-model="EditedUserData.email"
                        type="email"
                        class="mt-2"
                        :label="$t('Email')"
                        :errors="errors.email"
                      />
                    </b-col>
                    <b-col md="6">
                      <vx-input
                        v-model="EditedUserData.phone"
                        type="number"
                        class="mt-2"
                        :label="$t('Phone')"
                        :errors="errors.phone"
                      />
                    </b-col>
                    <b-col md="6">
                      <vs-textarea
                        v-model="UserData.address"
                        label="Address"
                      />
                    </b-col>
                    <b-col md="6">
                      <vs-textarea
                        v-model="UserData.ex_address"
                        label="Second Address"
                      />
                    </b-col>
                  </b-row>
                </b-form>
              </vs-col>
              <vs-col vs-w="12">
                <br>
                <br>
                <br>
              </vs-col>
              <vs-col
                vs-w="11"
                align="end"
              >
                <vs-button
                  color="success"
                  type="gradient"
                  class="mr-2 mb-2"
                  @click="ConfirmUserEdit()"
                >
                  {{ $t('Confirm') }}
                </vs-button>
              </vs-col>
            </vs-row>
          </div>
        </b-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
export default {
  /* eslint-disable */
  data: () => ({
    EditedUserData:{},
    UserId:'',
    errors:[],
    errorsPoints:[],
        value:'',
        type:'',

    StatusOption: ['Active', 'Blocked', 'Deactivated'],
    LanguageOption: ['Arabic', 'English', 'Turkish'],
  }),
  watch:{
    UserData(val){
      this.EditedUserData = Object.assign({},val)
    }
  },
  computed:{
    UserData() {
      return this.$store.getters["user/user"]
    },
    points(){
      return this.$store.getters["point/points"]
    }
  },
  methods: {
    getData(){
      this.$store.dispatch('user/GetUserData', this.UserId)
    },
    ConfirmUserEdit(){
      this.errors=[]
      if(!this.EditedUserData.avatar_id)
        this.EditedUserData.avatar_id= null
      this.$store.dispatch('user/EditUserData',this.EditedUserData).then(()=>{
        if(this.EditedUserData.id===this.$auth.GetAuth().id){
          this.$store.dispatch('auth/UpdateAuth')
        }
      }).catch((error) => {
        if(error.response.data.errors)
          this.errors= error.response.data.errors
      })
    },
    AddPoints() {
      this.errorsPoints=[]
      this.$store.dispatch('point/CreatePoints', { value: this.value,post_id: null,type: this.type,user_id: this.$route.params.userId }).then(() => {
        this.value = ''
        this.type = ''
      }).catch((error) => {
        if(error.response.data.errors)
          this.errorsPoints = error.response.data.errors
      })
    },
  },
  mounted() {

  },
  created() {
    this.$store.dispatch('point/getPoints', `?user_id=${this.$route.params.userId}` )
    this.UserId=this.$route.params.userId
    this.getData();
  }
}
</script>
<style>
.select-label-placeholder {
  position: absolute;
}
</style>
